<template>
  <div>
    <div class="mx-4">
      <div class="columns is-centered is-vcentered mt-3 m-4 border-btm-blue">
        <div
          v-for="(item, index) in camps"
          :key="index"
          class="column is-3 has-text-centered mx-4 text-white is-centered rounded-lg box box-light"
          style="align-self: flex-start"
        >
          <div class="ls-feature-card">
            <div class="card-content">
              <div class="media-content has-text-centered">
                <h3 class="title is-5 has-text-weight-bold">
                  <a href="/" class="has-text-dark" title="">{{ item.name }}</a>
                </h3>
                <a
                  class="is-small has-text-info"
                  @click.prevent="getContracts(item, $event)"
                  >View Contracts</a
                >
              </div>
            </div>
            <div>
              <footer class="card-footer columns">
                <div class="card-footer-item column">
                  <span class="has-text-info-dark">
                    <button
                      class=""
                      @click.prevent="open_modal(item, 'Expenses')"
                    >
                      Expenses
                    </button>
                  </span>
                </div>
                <div class="card-footer-item column">
                  <span class="has-text-info-dark">
                    <button @click.prevent="open_modal(item, 'Services')">
                      Services
                    </button>
                  </span>
                </div>
                <div class="card-footer-item column">
                  <span class="has-text-info-dark">
                    <button
                      class=""
                      @click.prevent="open_modal(item, 'Categories')"
                    >
                      Categories
                    </button>
                  </span>
                </div>
                <div class="card-footer-item column">
                  <span class="has-text-info-dark">
                    <button
                      class=""
                      @click.prevent="open_modal(item, 'Materials')"
                    >
                      Materials
                    </button>
                  </span>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
      <div class="container" v-if="contracts">
        <div class="columns">
          <div
            class="column"
            :class="create_form || edit_form ? 'is-9' : 'is-12'"
          >
            <div>
              Contracts for {{ selectedCamp.name }}
              <a @click.prevent="create_contract">
                <i class="fa fa-plus"></i>
              </a>
            </div>
            <ag-grid-vue
              style="width: 100%; height: 200px"
              class="ag-theme-balham"
              :columnDefs="columnDefs"
              :rowData="rowData"
              :animateRows="true"
              @grid-ready="onGridReady"
              :context="context"
              :facility_id="selectedCamp.guid"
              @first-data-rendered="onFirstDataRendered"
            >
            </ag-grid-vue>
          </div>

          <template v-if="create_form || edit_form">
            <div class="column is-3 border border-blue-50 p-3" style="">
              <ContractForm
                :facility_id="campGuid"
                @addedContracts="addContracts"
                v-if="create_form"
                @cancelForm="revertForm"
              />
              <ContractForm
                v-if="this.rowData.length > 0 && edit_form"
                :facility_id="campGuid"
                :selected_contract="selected_item"
                @updatedContracts="updateContracts"
                @cancelForm="revertForm"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Contract from "../services/ContractServices";
import Facility from "../services/Facility";
import ViewContracts from "@/components/buttonscell/ViewContracts.vue";
import GeneralModal from "@/components/modals/General.vue";
import ContractForm from "@/components/forms/createContract.vue";
import ActivitiesModal from "@/components/modals/ActivitiesModal.vue";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import helpers from "@/helpers.js";

import { $vfm } from "vue-final-modal";

import { AgGridVue } from "ag-grid-vue3";

export default {
  components: {
    AgGridVue,
    // ExpensesModal
    ContractForm,
  },
  data() {
    return {
      camps: [],
      count: -1,
      context: null,
      contracts: null,
      rowData: [],
      generalData: null,
      activities: null,
      create_form: null,
      edit_form: null,
      general: null,
      showModal: true,
      selectedCamp: { name: null },
      columnDefs: [
        {
          headerName: "Contract",
          field: "name",
          filter: true,
          floatingFilter: true,
          sortable: true,
        },
        {
          headerName: "Customer Name",
          field: "cuname",
          filter: true,
          floatingFilter: true,
          sortable: true,
        },

        {
          headerName: "Date of creation",
          field: "creation_date",
          filter: true,
          floatingFilter: true,
          valueFormatter: helpers.dateFormatter,
          sort: "desc",
          sortable: true,
        },
        {
          headerName: "Actions",
          // field: "creation_date",

          cellRenderer: ViewContracts,
          cellRendererParams: [this.context, this.campGuid],
        },
      ],
      gridApi: null,
      gridColumnApi: null,
      selected_item: {},
      selectedType: {},
      rowSelection: "single",
      campGuid: "",
    };
  },

  async created() {
    this.context = { componentParent: this };
  },
  async beforeMount() {
    this.camps = await Contract.getCamps();
    // console.log(this.camps);
  },
  methods: {
    revertForm() {
      this.edit_form = null;
      this.create_form = null;
      this.refreshCells();
    },
    showRow(item) {
      alert(JSON.stringify(item));
    },
    async getContracts(item) {
      this.campGuid = "";
      this.campGuid = item.guid;
      this.edit_form = null;
      this.create_form = null;

      this.rowData = await Contract.getContractsByCamp(this.campGuid);
      this.contracts = 1;
      this.selectedCamp.name = item.name;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      this.gridApi.sizeColumnsToFit();
    },
    onFirstDataRendered(params) {
      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();
    },

    async open_modal(item, apiname) {
      this.edit_form = null;
      this.create_form = null;

      this.count += 1;
      this[apiname.toLowerCase()] = 1;
      let functionName = "get" + apiname;

      this.generalData = null;
      this.selectedCamp = { name: null, guid: null };

      this.selectedCamp.name = item.name;
      this.selectedCamp.guid = item.guid;

      var req = {};
      req.guid = this.selectedCamp.guid;
      this.campGuid = this.selectedCamp.guid;
      req.place = "facility";

      this.generalData = await Facility[functionName](req);
      this.refreshCells();
      var params = this.generalData;
      params.name = apiname;
      params.facility_id = this.campGuid;
      // params.count = apiname;
      setTimeout(() => {
        $vfm.show({ component: GeneralModal, params });
      });
    },

    async create_contract() {
      this.create_form = 1;
      this.edit_form = null;
      this.refreshCells();
    },

    addContracts(a) {
      this.rowData = [...this.rowData, a];
      this.edit_form = null;

      this.create_form = null;
      this.refreshCells();
    },
    updateContracts(a) {
      this.rowData = null;
      this.rowData = a;
      this.create_form = null;
      this.edit_form = null;
      this.refreshCells();
    },
    async open(mode, contract) {
      if (mode == "1") {
        this.activities = null;

        this.selected_item = contract;

        let act = await Contract.getContractActivities(this.selected_item);

        var req = {};
        var params = {};
        req.guid = this.selected_item.facility_id;
        params.buildings = await Facility.getBuildings(req);
        params.services = await Facility.getServices(req);
        params.expenses = await Facility.getExpenses(req);
        params.materials = await Facility.getMaterials(req);
        params.contract = this.selected_item;
        params.allActivities = await Facility.getActivities();
        this.edit_form = null;
        this.create_form = null;

        params.activities = act;
        params.facility_id = this.campGuid;
        this.refreshCells();

        setTimeout(() => {
          $vfm.show({
            component: ActivitiesModal,
            params,
          });
        });
      } else if (mode == 0) {
        this.selected_item = null;
        this.create_form = null;
        this.edit_form = null;
        if (contract) {
          this.selected_item = contract;

          this.edit_form = 1;
          this.refreshCells();
        }
      }
    },

    refreshCells() {
      setTimeout(() => {
        this.gridApi.sizeColumnsToFit();
        this.gridApi.refreshCells();
      });
    },
  },
};
</script>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  font-size: 1.5rem;
  font-weight: 700;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.column.is-3.has-text-centered.mx-4.text-white.is-centered.rounded-lg.box.box-light:hover {
  background: #ebf3fb;
}
</style>
