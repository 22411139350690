<template>
  <div class="mb-3 border-b border-blue-50">
    <div class="is-size-6">
      {{ type[0].toUpperCase() + type.substring(1) }} List
      <a @click.prevent="mode = mode == 1 ? 0 : 1" class="is-pulled-right">
        <i class="fas fa-pencil fa-xs"></i>
      </a>
      <ul v-if="mode == 1" class="mt-2 is-small is-size-7">
        <li class="columns p-0 m-0 mb-1 border-b border-blue-50">
          <div
            class="column is-size-7 is-5 m-0 p-0 pl-2 has-text-right has-text-black is-flex is-align-items-center"
          >
            {{ head[0] }}
          </div>
          <template v-if="head.length > 1">
            <div
              class="column is-size-7 is-3 m-0 p-0 has-text-right has-text-black is-flex is-align-items-center"
            >
              {{ head[1] }}
            </div>
            <div
              class="column is-size-7 is-3 m-0 p-0 has-text-right has-text-black is-flex is-align-items-center"
            >
              {{ head[2] }}
            </div>
          </template>
        </li>
        <template v-for="item in list" :key="item.id">
          <li class="columns p-0 m-0 mb-1 border border-blue-50">
            <div class="column is-size-7 is-6 m-0 p-0 pl-2">
              {{ item.name }}
            </div>
            <template v-if="head.length > 1">
              <div
                class="column is-size-7 is-2 m-0 p-0 has-text-right is-flex is-align-items-center"
              >
                <a
                  href=""
                  @click.prevent="
                    item.relativeSelected = item.relativeSelected == 1 ? 0 : 1
                  "
                >
                  <i class="fa fa-square" v-if="item.relativeSelected == 0">
                  </i>
                  <i class="fa fa-check-square" v-else> </i>
                </a>
              </div>
              <div
                class="column is-size-7 is-2 m-0 p-0 has-text-right is-flex is-align-items-center"
              >
                <a
                  v-if="item.price != item.price_was"
                  @click.prevent="item.price = item.price_was"
                >
                  <i class="fa fa-undo mr-1"></i
                ></a>
                <input
                  type="text"
                  v-model="item.price"
                  :disabled="item.relativeSelected == 1 ? 'disabled' : false"
                  :class="[
                    item.price != item.price_was ? 'has-text-danger' : '',
                  ]"
                  class="input is-small has-text-centered"
                />
              </div>
            </template>

            <div
              class="column is-size-7 m-0 p-0 has-text-right"
              :class="head.length > 1 ? 'is-2' : 'is-6'"
            >
              <template v-if="item.has_stock != 1">
                <button
                  v-if="item.selected == 0 || item.selected == false"
                  class="button is-small is-success"
                  @click="addItem(item)"
                >
                  <i class="fas fa-plus"></i>
                </button>
                <button
                  v-else
                  class="button is-small is-outlined is-danger"
                  @click="addItem(item)"
                >
                  <i class="fas fa-minus"></i>
                </button>
                <!--  -->
              </template>

              <template v-else>
                <button
                  class="button is-small is-outlined is-black"
                  @click="item.show_list = !item.show_list"
                >
                  <i class="fas fa-arrow-right"></i>
                </button>
              </template>
            </div>
          </li>
          <template v-if="item.has_stock == 1 && item.show_list">
            <div class="">
              <div v-for="mat in general.materials" :key="mat.id">
                <span class="border border-blue-400 is-size-7 p-1">
                  x{{ mat.amount }} {{ mat.name }}
                  <a @click.prevent="removeMaterial(mat)"
                    ><i class="fa fa-close"></i></a
                ></span>
              </div>
            </div>
            <ul class="m-3">
              <li class="p-2">
                <span>
                  <input
                    type="text"
                    v-model="search"
                    class="input is-small column is-5"
                    placeholder="search..."
                /></span>
              </li>
              <li
                class="columns p-0 pl-3 m-0 mb-1 border-b border-blue-50 is-size-7 has-text-black"
              >
                <div class="column is-6 pl-2">Name</div>
                <div class="column is-2 has-text-right">Amount</div>
                <div class="column is-2 has-text-right">Price</div>
              </li>
              <li
                v-for="material in materials
                  .filter((m) =>
                    m.name.toLowerCase().includes(search.toLowerCase())
                  )
                  .splice(0, 10)"
                :key="material.id"
                class="columns p-0 pl-3 pb-1 m-0 mb-1 border-b border-blue-50"
              >
                <div class="column is-size-7 is-6 m-0 p-0 pl-2">
                  {{ material.name }}
                </div>

                <div class="column is-size-7 is-2 m-0 p-0 has-text-right">
                  <input
                    type="number"
                    min="1"
                    style="float: right"
                    v-model="material.amount"
                    class="input is-small column is-8"
                    placeholder="amount"
                  />
                </div>
                <div class="column is-size-7 is-2 m-0 p-0 has-text-right">
                  {{ material.price }}
                </div>
                <div class="column is-size-7 is-2 m-0 p-0 has-text-right">
                  <button
                    class="button is-small is-outlined is-success"
                    @click="addMaterial(material)"
                    v-if="!material.selected"
                  >
                    <i class="fas fa-plus" v-if="!material.selected"></i>
                  </button>
                  <button
                    class="button is-small is-outlined is-danger"
                    @click="addMaterial(material)"
                    v-else
                  >
                    <i class="fas fa-minus"></i>
                  </button>
                </div>
              </li>
            </ul>
          </template>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";
import { useGeneralStore } from "../stores/general";
import Facility from "../services/Facility";
export default {
  name: "ActivityDetails",
  data() {
    return {
      general: storeToRefs(useGeneralStore()),
      mode: 0 /* 0 view, 1 edit*/,
      list: [],
      materials: [],
      search: "",
      head: {},
      selectedMaterials: [],
      selectedItems: [],
    };
  },
  props: ["type", "facility_id", "selectedContractActivity"],
  async created() {
    var req = {
      guid: this.facility_id,
    };

    let a = this.selectedContractActivity;
    switch (this.type) {
      case "services":
        this.list = await Facility.getServices(req);

        this.head = ["Name", "Relative Value", "Price"];
        this.list = [
          ...this.list.map((l) => {
            l.price_was = l.price;
            console.log(a, "aa");
            let ama = a?.services;

            if (
              ama !== undefined &&
              a?.guid !== undefined &&
              ama.some((b) => b.service_id == l.id)
            ) {
              l.relativeSelected = ama.find(
                (b) => b.service_id == l.id
              ).relative_price;
              l.selected = 1;
              l.activity_guid = ama.find((b) => b.service_id == l.id).guid;
              let pr = ama.find((b) => b.service_id == l.id).price;
              l.price = pr;
            } else {
              l.relativeSelected = 1;
              l.selected = 0;
              l.activity_guid = null;
            }

            return l;
          }),
        ];
        console.log(this.list, "my list");
        this.general.services = this.list.filter((a) => a.selected);

        break;
      case "expenses":
        this.list = await Facility.getExpenses(req);

        this.head = ["Name"];
        console.log(a?.expenses, "expenses");

        this.list = [
          ...this.list.map((l) => {
            l.price_was = l.price;
            let ama = a?.expenses;
            if (
              ama !== undefined &&
              a?.guid !== undefined &&
              ama.some((b) => b.expense_id == l.id)
            ) {
              console.log(l, "l");

              l.selected = 1;
              l.activity_guid = ama.find((b) => b.expense_id == l.id).guid;
            } else {
              l.selected = 0;
              l.activity_guid = null;
            }

            return l;
          }),
        ];

        this.general["expenses"] = this.list.filter((a) => a.selected);

        this.materials = await Facility.getMaterials(req);

        this.materials = [
          ...this.materials.map((m) => {
            let mat = a?.materials;
            if (
              mat !== undefined &&
              a?.guid !== undefined &&
              mat.some((b) => b.material_id == m.id)
            ) {
              m.selected = 1;
              m.amount = mat.find((b) => b.material_id == m.id).material_amount;
              m.activity_guid = mat.find((b) => b.material_id == m.id).guid;
            } else {
              m.selected = 0;
              m.amount = 1;
              m.activity_guid = null;
            }

            return m;
          }),
        ];

        this.general.materials = this.materials.filter((m) => m.selected);

        break;
    }
  },
  methods: {
    addMaterial(material) {
      material.selected = !material.selected;
      this.general.materials = this.materials.filter((m) => m.selected);
    },
    removeMaterial(material) {
      this.materials = [
        ...this.materials.map((m) => {
          if (m.id === material.id) {
            m.selected = false;
          }
          return m;
        }),
      ];
      this.general["materials"] = this.materials.filter((a) => a.selected);
    },

    addItem(item) {
      item.selected = !item.selected;

      this.general[this.type] = this.list.filter((a) => a.selected);

      // this.$emit("addSelectedItems", this.selectedItems);
    },
  },
};
</script>

<style lang="scss" scoped></style>
