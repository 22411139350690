import Api from "@/services/Api";

export default {
  async getCamps() {
    var camps = [];
    await Api(true)
      .post("contracts/camps", {})
      .then((r) => {
        camps = r.data;
      });
    // debugger; // eslint-disable-line no-debugger
    return camps;
  },

  async getContractsByCamp(camp) {
    var contracts = [];
    await Api(true)
      .post("contracts/", { camp })
      .then((r) => {
        contracts = r.data;
      });
    // debugger; // eslint-disable-line no-debugger
    return contracts;
  },
  async getContractActivities(contract) {
    var activities = [];
    await Api(true)
      .post("contracts/activities/", { contract })
      .then((r) => {
        activities = r.data;
      });
    return activities;
  },
  async saveContractActivity(activity) {
    var activities = [];
    await Api(true)
      .post("contracts/activities/save", { activity })
      .then((r) => {
        debugger; // eslint-disable-line no-debugger

        activities = r.data.map((a) => {
          a.id = a.guid;

          return a;
        });
      });
    return activities;
  },
  async saveContract(contract) {
    var contracts = [];
    await Api(true)
      .post("contracts/save", { contract })
      .then((r) => {
        contracts = r.data;
      });
    return contracts;
  },
  async updateContractActivity(activity) {
    var activities = [];
    await Api(true)
      .post("contracts/activities/edit", { activity })
      .then((r) => {
        activities = r.data.map((a) => {
          a.id = a.guid;
          return a;
        });
        debugger; // eslint-disable-line no-debugger
      });
    return activities;
  },
};
