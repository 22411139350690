<template>
  <div>
    <vue-final-modal
      v-model="showModal"
      classes="modal-container"
      content-class="modal-content"
    >
      <span class="modal__title">{{ modalTitle }} </span>
      <div class="modal__content" style="background: #f5f4f4">
        <table
          class="table table-striped table-bordered"
          style="background: #f5f4f4"
        >
          <thead>
            <tr>
              <th
                v-for="(d, a) in generalRule.filter(
                  (rule) => rule.visible === true
                )"
                :key="a.id"
                class="has-border h-30"
                :width="d.width"
                :style="
                  d.type !== 'text' ? 'text-align:center' : 'text-align:left'
                "
              >
                <span :style="d.visible ? 'display:block' : 'display:none'">
                  {{ d.label }}</span
                >
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(itema, indexa) in tableData" :key="indexa">
              <td
                v-for="(d, a) in generalRule"
                :key="a + ' ' + indexa"
                :style="
                  d.type !== 'text' ? 'text-align:center' : 'text-align:left'
                "
              >
                <span v-if="d.type == 'color'">
                  <input
                    type="color"
                    class="checkbox-large"
                    v-model="itema[d.name]"
                    v-if="!itema.edit_mode"
                    style="height: 32px"
                  />
                  <i
                    class="fa fa-circle"
                    :style="`color:` + itema[d.name]"
                    v-else
                  ></i>
                </span>
                <span v-else-if="d.type == 'bool'">
                  <!-- <input
                    type="checkbox"
                    class="checkbox checkbox-large"
                    v-model="itema[d.name]"
                    true-value="1"
                    false-value="0"
                    v-if="!itema.edit_mode"
                  />

                  <i
                    :class="
                      itema[d.name]
                        ? 'fa fa-check-circle '
                        : 'fa fa-circle-xmark'
                    "
                    :style="itema[d.name] ? 'color:#8ae38a' : 'color:#dd7373'"
                    v-else
                  ></i> -->

                  <a
                    href=""
                    v-if="!itema.edit_mode"
                    @click.prevent="itema[d.name] = itema[d.name] == 1 ? 0 : 1"
                  >
                    <i
                      class="fa fa-2x fa-circle-xmark"
                      style="color: #dd7373"
                      v-if="itema[d.name] == 0"
                    >
                    </i>
                    <i
                      class="fa fa-2x fa-check-circle"
                      style="color: #8ae38a"
                      v-else
                    >
                    </i>
                  </a>
                  <i
                    :class="
                      itema[d.name]
                        ? 'fa fa-check-circle '
                        : 'fa fa-circle-xmark'
                    "
                    :style="itema[d.name] ? 'color:#8ae38a' : 'color:#dd7373'"
                    v-else
                  ></i>
                </span>
                <span v-else-if="d.type == 'button'">
                  <button
                    v-if="itema.edit_mode"
                    class="button"
                    @click.prevent="edit(itema)"
                  >
                    <i class="fa fa-pencil"></i>
                  </button>
                  <div v-else class="flex">
                    <button
                      class="button is-success "
                      style="height:32px"

                      @click.prevent="save(itema)"
                    >
                      <i class="fa fa-check"></i>
                    </button>
                    <div class="column is-2"></div>
                    <button
                      class="button is-outlined"
                      style="height:32px"
                      @click.prevent="revert(itema)"
                    >
                      <i class="fa fa-cancel"></i>
                    </button>
                  </div>
                </span>
                <span
                  :style="d.visible ? 'display:block' : 'display:none'"
                  v-else
                >
                  <input
                    type="text"
                    class="input"
                    v-model="itema[d.name]"
                    v-if="!itema.edit_mode"
                  />
                  <span v-else> {{ itema[d.name] }}</span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import { VueFinalModal } from "vue-final-modal";
import Facility from "../../services/Facility";

export default {
  data: () => ({
    showModal: true,
    tableData: null,
    Expenses: null,
    Services: null,
    Categories: null,
    customerData: null,
    modalTitle: "",
    myModal: null,
    Materials: null,
    generalRule: [],
    editFields: 0,
  }),
  components: {
    VueFinalModal,
  },

  created() {
    this.Expenses = [
      {
        name: "name",
        label: "Name",
        visible: true,
        type: "text",
        width: " 50%",
      },
      {
        name: "color",
        label: "Color",
        visible: true,
        type: "color",
        width: "40%",
      },
      {
        name: "invoiceable",
        label: "Invoiceable",
        visible: true,
        type: "bool",
        width: " 50%",
      },
      { name: "", label: "Actions", visible: true, type: "button" },
      { name: "id", label: "ID", visible: false, type: "number", width: 0 },
    ];
    this.Categories = [
      {
        name: "name",
        label: "Name",
        visible: true,
        type: "text",
        width: "40%",
      },
      {
        name: "color",
        label: "Color",
        visible: true,
        type: "color",
        width: "10%",
      },
      {
        name: "invoiceable",
        label: "Invoiceable",
        visible: true,
        type: "bool",
        width: "10%",
      },
      {
        name: "maintenance_service",
        label: "Maintenance Service",
        visible: true,
        type: "bool",
        width: "100%",
      },
      { name: "", label: "Actions", visible: true, type: "button" },

      { name: "id", label: "ID", visible: false, type: "number", width: "0%" },
    ];

    this.Services = [
      { name: "name", label: "Name", visible: true, type: "text" },
      { name: "cost", label: "Cost", visible: true, type: "text" },
      { name: "price", label: "Price", visible: true, type: "text" },

      {
        name: "currency_sign",
        label: "Currency",
        visible: true,
        type: "text",
      },
      {
        name: "priceable",
        label: "Priceable",
        visible: true,
        type: "bool",
      },
      {
        name: "maintenance_service",
        label: "Maintenance Service",
        visible: true,
        type: "bool",
      },
      { name: "", label: "Actions", visible: true, type: "button" },
      { name: "id", label: "ID", visible: false, type: "number", width: "0%" },
    ];
    this.Materials = [
      { name: "name", label: "Name", visible: true, type: "text" },
      {
        name: "selling_price",
        label: "Selling Price",
        visible: true,
        type: "text",
      },
      { name: "price", label: "Price", visible: true, type: "text" },

      {
        name: "currency_sign",
        label: "Currency",
        visible: true,
        type: "text",
      },
      // {
      //   name: "priceable",
      //   label: "Priceable",
      //   visible: true,
      //   type: "bool",
      // },
      {
        name: "invoiceable",
        label: "Invoiceable",
        visible: true,
        type: "bool",
      },
      { name: "", label: "Actions", visible: true, type: "button" },
      { name: "id", label: "ID", visible: false, type: "number", width: "0%" },
    ];
    this.modalTitle = "";
    this.myModal =
      this.$vfm.dynamicModals[this.$vfm.dynamicModals.length - 1].params;

    //TODO: me v-if shif indexin e modalit, check again doc

    this.generalRule =
      this[
        this.$vfm.dynamicModals[this.$vfm.dynamicModals.length - 1].params.name
      ];
    this.tableData = this.myModal.map((element) => {
      element.show_sections = false;
      element.edit_mode = true;
      element.save_mode = true;
      element.delete_mode = false;
      Object.keys(element).forEach((key) => {
        if (!["edit_mode", "save_mode", "delete_mode"].includes(key))
          element[key + "_original"] = element[key];
      });

      return element;
    });
    console.log(this.tableData);
    this.modalTitle = this.myModal.name;
  },

  methods: {
    edit(d) {
      this.tableData = [
        ...this.tableData.map((row) => {
          if (row.id == d.id) {
            row.edit_mode = !row.edit_mode;
          }

          return row;
        }),
      ];
      d.edit_mode = false;
      d.save_mode = true;
      d.revert_mode = true;

      console.log(d);
    },
    async save(d) {
      let req = {};
      req.name = this.modalTitle.toLowerCase();
      req.data = d;

      debugger; // eslint-disable-line no-debugger

      let items = await Facility.saveGeneral(req);
      console.log(items);
      // this.tableData = [
      //   ...this.tableData.map((row) => {
      //     if (row.id == d.id) {
      //       row.edit_mode = !row.edit_mode;
      //     }

      //     return row;
      //   }),
      // ];
      d.edit_mode = true;
      d.save_mode = false;
      d.revert_mode = false;

      console.log(d);
    },
    revert(d) {
      Object.keys(d).forEach((key) => {
        if (
          !["edit_mode", "save_mode", "delete_mode"].includes(key) &&
          !key.includes("_original")
        ) {
          let temp = d[key + "_original"];
          d[key] = temp;
        }
      });

      d.edit_mode = true;
      d.save_mode = false;
      d.revert_mode = false;
      // console.log(d);
    },
  },
};
</script>
<style scoped>
.checkbox-large {
  width: 100%;
  height: 35px;
}
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  width: auto;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #f5f4f4 !important;
}
.modal__title {
  font-size: 1.5rem;
  font-weight: 700;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>
