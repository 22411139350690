import { defineStore } from "pinia";

export const useGeneralStore = defineStore({
  id: "general",
  state: () => ({
    services: [],
    expenses: [],
    materials: [],
  }),
  getters: {},
  actions: {},
});
