<template>
  <div class="">
    <div>
      <label for=""> Contract Name*</label>
      <input class="input is-small" required type="text" v-model="contract" />
    </div>
    <div class="is-fullwidth my-2">
      <label for=""> Start of Execution</label>

      <div class="control has-icons-right">
        <Datepicker
          v-model="start_of_exec"
          :format="'dd/MM/yyyy HH:mm'"
          autoApply
          :closeOnAutoApply="false"
        />
      </div>
    </div>
    <div class="is-flex is-justify-content-center">
      <a
        href=""
        @click.prevent="createCustomer"
        class="button is-navcolor is-small ml-1 my-2"
      >
        New Customer</a
      >
      <a
        href=""
        @click.prevent="choose"
        class="button is-navcolor is-small mr-1 my-2"
      >
        Choose Customer</a
      >
    </div>
    <div class="columns">
      <div
        :class="chooseCustomer == true ? 'column' : ''"
        v-if="chooseCustomer"
      >
        <label for="">Customer* </label>
        <div class="select is-small is-fullwidth">
          <select v-model="customer">
            <option
              v-for="key in customers"
              :key="key.id"
              :value="key.customer_id"
            >
              {{ key.name }}
            </option>
          </select>
        </div>
      </div>
      <div :class="newCustomer == true ? 'column' : ''" v-else>
        <template v-if="newCustomer">
          <label for=""> New Customer* </label>
          <input
            class="input is-small"
            required
            type="text"
            v-model="customer"
          />
        </template>
      </div>
    </div>

    <div class="is-pulled-right" v-if="customer != '' && contract != ''">
      <div>
        <span class="">
          <a
            href=""
            class="button is-small is-success"
            @click.prevent="saveContract"
          >
            Save</a
          >
          <!--TODO: show button only if selected customer  -->
        </span>
      </div>
    </div>
    <div class="is-pulled-left">
      <div>
        <span class="">
          <a href="" class="button is-small" @click.prevent="cancelForm">
            Cancel</a
          >
          <!--TODO: show button only if selected customer  -->
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Facility from "../../services/Facility";
import Contract from "../../services/ContractServices";
import "@vuepic/vue-datepicker/dist/main.css";
import Datepicker from "@vuepic/vue-datepicker";
export default {
  props: ["facility_id", "selected_contract"],
  components: {
    Datepicker,
  },
  data() {
    return {
      customers: {},
      customer: "",
      contract: "",
      name: {},
      newCustomer: false,
      chooseCustomer: false,
      start_of_exec: new Date(),
    };
  },
  async created() {
    this.getData();
    this.customers = await Facility.getCustomers(this.facility_id);
  },
  watch: {
    selected_contract() {
    
      this.getData();
    },
  },
  methods: {
    getData() {
      if (this.selected_contract != undefined) {
        this.contract = this.selected_contract.name;
        this.start_of_exec = this.selected_contract.start_of_exec;
        this.chooseCustomer = true;
        this.customer = this.selected_contract.cu_guid;
      }
    },
    cancelForm() {
      this.$emit("cancelForm");
    },
    async choose() {
      this.chooseCustomer = true;
      this.newCustomer = false;
    },
    createCustomer() {
      this.customer = "";
      this.chooseCustomer = false;
      this.newCustomer = true;
    },
    async saveContract() {
      let req = {
        contract: this.contract,
        customer: this.customer,
        facility_id: this.facility_id,
        start_of_exec: this.start_of_exec,
        guid: null,
        mode: "create",
      };
      if (this.chooseCustomer) {
        req.newCustomer = 0;
      } else if (this.newCustomer) {
        req.newCustomer = 1;
      }
      if (this.selected_contract !== undefined) {
        req.guid = this.selected_contract.guid;
        req.mode = "edit";
      }
      if (this.selected_contract !== undefined) {
        let contracts = await Contract.saveContract(req);
        this.$emit("updatedContracts", contracts);
      } else {
        let contracts = await Contract.saveContract(req);
        this.$emit("addedContracts", contracts);
      }
    },
  },
};
</script>
<style scoped>
.is-navcolor {
  background: #2b5177;

  color: white;
}
</style>
