import Api from "@/services/Api";

export default {
  async getExpenses(guid) {
    var expenses = [];
    await Api(true)
      .post("facility/expenses", { guid })
      .then((r) => {
        expenses = r.data;
      });
    // debugger; // eslint-disable-line no-debugger
    return expenses;
  },
  async getActivities() {
    var activities = [];
    await Api(true)
      .post("facility/activities")
      .then((r) => {
        activities = r.data;
        activities.map((a) => {
          a.id = a.guid;
          return a;
        });
      });
    // debugger; // eslint-disable-line no-debugger
    return activities;
  },
  async getServices(guid) {
    var services = [];
    await Api(true)
      .post("facility/services", { guid })
      .then((r) => {
        services = r.data;
      });
    // debugger; // eslint-disable-line no-debugger
    return services;
  },
  async getCategories(guid) {
    var categories = [];
    await Api(true)
      .post("facility/categories", { guid })
      .then((r) => {
        categories = r.data;
      });
    // debugger; // eslint-disable-line no-debugger
    return categories;
  },
  async getMaterials(guid) {
    var materials = [];
    await Api(true)
      .post("facility/materials", { guid })
      .then((r) => {
        materials = r.data;
      });
    // debugger; // eslint-disable-line no-debugger
    return materials;
  },
  async getCustomers(facility_id) {
    var customers = [];
    await Api(true)
      .post("facility/customers", { facility_id })
      .then((r) => {
        customers = r.data;
        customers.map((a) => {
          a.id = a.guid;
          return a;
        });
      });
    // debugger; // eslint-disable-line no-debugger
    return customers;
  },
  async getBuildings(guid) {
    var buildings = [];
    await Api(true)
      .post("facility/buildings", { guid })
      .then((r) => {
        buildings = r.data;
      });
    // debugger; // eslint-disable-line no-debugger
    return buildings;
  },

  async getBuildingSections(building) {
    var sections = [];
    await Api(true)
      .post("facility/sections", { building })
      .then((r) => {
        sections = r.data;
      });
    // debugger; // eslint-disable-line no-debugger
    return sections;
  },

  async saveGeneral(camp) {
    var items = [];
    await Api(true)
      .post("facility/" + camp.name, { camp })
      .then((r) => {
        items = r.data;
      });
    // debugger; // eslitemsint-disable-line no-debugger
    return items;
  },
};
