<template>
  <div>
    <vue-final-modal
      v-model="showModal"
      classes="modal-container"
      content-class="modal-content"
    >
      <div class="has-text-centered is-size-6">
        <div
          style="font-weight: 600; background: rgb(222 238 255); color: black"
          class="p-3"
        >
          <span class="contract_icon">
            <i class="fa fa-light fa-file-contract"></i>
          </span>
          {{ contract.name }}
        </div>
      </div>
      <div class="modal__content">
        <div class="has-text-right is-size-4" v-if="!create_form">
          <a @click.prevent="create_activity">
            <i class="fa fa-circle-plus" style="color: #001438"></i>
          </a>
        </div>
        <div class="">
          <div :class="create_form ? 'column' : ''">
            <div v-if="create_form == true">
              <div class="column is-size-7">
                <ActivitiesForm
                  :expenses="expenses"
                  :services="services"
                  :activities="activities"
                  :buildings="buildings"
                  :selectedContractActivity="selectedActivity"
                  :contract="contract"
                  :materials="materials"
                  :facility_id="facility_id"
                  class="border-b"
                  :title="'Create a new Activity'"
                  @saveAct="addActivity($event)"
                />
              </div>
            </div>
          </div>
          <div class="container-fluid">
            <Vue3EasyDataTable
              v-if="items.length > 0"
              :headers="headers"
              :items="items"
              :theme-color="themeColor"
              @expand-row="getActivityData"
              :rows-per-page="5"
            >
              <template #expand="item">
                <div class="">
                  <ActivitiesForm
                    :expenses="expenses"
                    :services="services"
                    :activities="activities"
                    :buildings="buildings"
                    :title="''"
                    :selectedContractActivity="item"
                    :contract="contract"
                    :materials="materials"
                    :facility_id="facility_id"
                    @updateAct="editActivity($event)"
                  />
                </div>
              </template>
            </Vue3EasyDataTable>
          </div>
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import { VueFinalModal } from "vue-final-modal";
import ActivitiesForm from "../ActivitiesForm";
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import { Item } from "vue3-easy-data-table";

export default {
  components: {
    VueFinalModal,
    ActivitiesForm,
    Vue3EasyDataTable,
  },
  data: () => ({
    showModal: true,
    activities: null,
    expenses: null,
    services: null,
    materials: null,
    buildings: null,
    myActivities: null,
    selectedActivity: null,
    edited: false,
    create_form: false,
    show_table: true,
    edit_form: false,
    cu_fa: null,
    facility_id: null,
    contract: null,
    themeColor: "#f48225",
    headers: [
      { text: "Subject", value: "Subject", width: 50 },
      { text: "Rounds", value: "Rounds", width: 50 },
      { text: "Repeat", value: "every", sortable: true, width: 50 },
      { text: "First Maintenance", value: "First Maintenance", width: 50 },
      { text: "Start Date", value: "Start of Execution", width: 50 },
      { text: "Building", value: "Building_name", sortable: true, width: 50 },
    ],
    items: Item,
  }),

  async created() {
    var params =
      this.$vfm.dynamicModals[this.$vfm.dynamicModals.length - 1].params;
    this.myActivities = params.activities;
    this.items = this.myActivities;
    this.expenses = params.expenses;
    this.contract = params.contract;
    this.services = params.services;
    this.buildings = params.buildings;
    this.facility_id = params.facility_id;
    this.activities = params.allActivities;
    this.materials = params.materials;
    if (this.items.length <= 0) {
      this.create_form = true;
    }
  },

  methods: {
    getActivityData() {
      this.edit_form = true;
      this.create_form = false;
    },
    create_activity() {
      this.edit_form = false;
      this.create_form = true;
    },

    addActivity(act) {
      act = [
        ...act.map((a) => {
          delete a.id;

          return a;
        }),
      ];

      this.items = [...this.items, act[0]];
      this.create_form = false;
    },
    editActivity(act) {
      this.items = [
        ...this.items.map((i) => {
          if (act[0].guid == i.guid) {
            i = act[0];
          }
          return i;
        }),
      ];

      this.myActivities = this.items;
      this.edited = true;
    },
  },
};
</script>
<style>
table th {
  width: 20px !important;
}
* {
  font-family: sans-serif;
}
:root {
  --easy-table-header-item-padding: 15px 0px;
  --easy-table-body-item-padding: 10px 5px;
  --easy-table-body-row-font-color: #000000;
  --easy-table-body-row-background-color: #deeeff;
  --easy-table-body-row-hover-font-color: #000000;
  --easy-table-body-row-hover-background-color: #5e7c9b00;
  --easy-table-row-border: 1px solid #ffffff;
  --easy-table-header-font-color: white;
  --easy-table-header-background-color: #5e7c9b;
}

.expand > div:first-child {
  background: white !important;
}
</style>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  width: 700px;
  background: #fff;
}
.modal__title {
  font-size: 1.5rem;
  font-weight: 700;
}
.vue3-easy-data-table__body td.expand {
  background: #e2e8f0 !important;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.buttons-pagination .item.button {
  font-size: 0.75rem !important;
}
.contract_icon i {
  color: white;
  border: 1px solid #5e7c9b;
  padding: 9px;
  border-radius: 50%;
  /* width: 6%; */
  background: #5e7c9b;
}
</style>
