<template>
  <div>
    <div>
      <h3 class="border-b border-blue-50 has-text-centered pb-2">
        {{ title }}
      </h3>
    </div>

    <div class="mt-3 px-3 py-1">
      <div class="columns">
        <div class="column is-5">
          <label for="sections"> Subject*</label>

          <div class="is-fullwidth mb-1">
            <textarea class="textarea is-small" rows="4" v-model="subject">
            </textarea>
          </div>
          <label for="sections"> Activity*</label>

          <div class="select is-fullwidth">
            <select
              name="Activities"
              id="activities"
              class="form-control"
              v-model="selectedActivity"
            >
              <option
                v-for="(act, key) in activities"
                :key="key"
                :value="act.id"
              >
                {{ act.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="column">
          <div class="border-b border-blue-50">
            <div class="">
              <label for="buildings"> Building*</label>
              <div class="select is-fullwidth">
                <select
                  name="buildings"
                  id="buildings"
                  @change="getSections()"
                  v-model="selectedBuilding"
                >
                  <option
                    v-for="(act, key) in buildings"
                    :key="key"
                    :value="act.id"
                  >
                    {{ act.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="my-2 is-4">
              <label for="sections"> Floor</label>
              <div class="select is-fullwidth">
                <select name="sections" id="sections" v-model="selectedSection">
                  <option
                    v-for="(act, key) in sections"
                    :key="key"
                    :value="act.id"
                  >
                    {{ act.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="my-2 is-3">
              <label class="" for=""> Units</label>

              <div class="is-fullwidth">
                <input
                  type="number"
                  class="input is-small"
                  min="1"
                  placeholder="Min. 1"
                  v-model="selectedUnits"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ActivityDetails
        v-if="showDetails"
        :facility_id="contract.facility_id"
        :type="'services'"
        :selectedContractActivity="selectedContractActivity"
      />
      <ActivityDetails
        v-if="showDetails"
        :facility_id="contract.facility_id"
        :type="'expenses'"
        :selectedContractActivity="selectedContractActivity"
      />

      <div class="columns is-small is-size-7">
        <div class="column">
          <label for=""> Rounds</label>

          <div class="control has-icons-right">
            <input
              type="number"
              class="input is-small is-size-7"
              placeholder=" Ex: 1"
              v-model="selectedRound"
              required
              min="1"
            />
          </div>
        </div>
        <div class="column">
          <label for=""> Every </label>

          <div class="control has-icons-right is-flex">
            <input
              type="number"
              class="input is-small is-size-7"
              v-model="selectedDays"
              placeholder=" Ex: Every 3 days"
              required
              min="1"
            />
            <div class="select is-fullwidth is-small">
              <select name="" id="" v-model="selectedPeriod">
                <option value="0">Days</option>
                <option value="1">Weeks</option>
                <option value="2">Months</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-small">
        <div class="column">
          <label for="sections"> First Maintenance</label>

          <div class="is-fullwidth my-2">
            <div class="control has-icons-right">
              <Datepicker
                v-model="firstMaintenance"
                :format="'dd/MM/yyyy HH:mm'"
                autoApply
                class="is-size-7"
                :closeOnAutoApply="false"
              />
            </div>
          </div>
        </div>
        <div class="column">
          <label for="sections"> Start of Execution</label>

          <div class="is-fullwidth my-2">
            <div class="control has-icons-right">
              <Datepicker
                v-model="selectedStart"
                :format="'dd/MM/yyyy HH:mm'"
                autoApply
                :closeOnAutoApply="false"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="is-fullwidth has-text-right mb-3">
        <button class="button is-success is-small" @click="saveActivity">
          Save Changes
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Facility from "../services/Facility";
import ActivityDetails from "./ActivityDetails";
import "@vuepic/vue-datepicker/dist/main.css";
import Datepicker from "@vuepic/vue-datepicker";
import { storeToRefs } from "pinia";
import { useGeneralStore } from "../stores/general";
import ContractServices from "../services/ContractServices";
// import { ref, reactive } from "vue";

export default {
  props: [
    "activities",
    "services",
    "expenses",
    "buildings",
    "contract",
    "title",
    "facility_id",
    "selectedContractActivity",
  ],
  components: {
    Datepicker,
    ActivityDetails,
  },

  data() {
    return {
      general: storeToRefs(useGeneralStore()),
      selectedBuilding: null,
      selectedActivity: null,
      selectedSection: null,
      selectedRound: null,
      selectedDays: null,
      firstMaintenance: new Date(),
      selectedPeriod: null,
      subject: null,
      is_create: true,
      showDetails: false,
      selectedExpense: [],
      selectedService: [],
      selectedUnits: null,
      myActivities: [],
      materials: null,
      selectedMaterials: [],
      selectedStart: new Date(),
      sections: null,
      showMaterials: 0,
    };
  },

  created() {
    let a = this.selectedContractActivity;
    if (a?.guid !== undefined) {
      (this.selectedBuilding = a.building_id),
        (this.subject = a["Subject"].split(" /")[0]);
      (this.selectedSection = a.floor_id), (this.selectedUnits = a["Units"]);
      this.selectedActivity = a.activity_id;
      this.selectedRound = a["Rounds"];
      this.selectedDays = a["Repeat"];
      this.selectedPeriod = a["period"];

      this.selectedStart = a["Start of Execution"];
      this.is_create = false;
      this.firstMaintenance = a["First Maintenance"];
      this.selectedUnits = a.units;
      this.sections = this.getSections();
    }
    this.showDetails = true;

    this.myActivities = this.activities;
  },

  methods: {
    async getSections() {
      let req = {};
      req.building = this.selectedBuilding;
      req.facility_id = this.contract.facility_id;

      this.sections = await Facility.getBuildingSections(req);
    },

    async saveActivity() {
      this.showDetails = false;

      var activity = {
        services: this.general.services,
        expenses: this.general.expenses,
        materials: this.general.materials,
        activity: this.selectedActivity,
        building: this.selectedBuilding,
        section: this.selectedSection,
        units: this.selectedUnits,
        contract: this.contract,
        period: this.selectedPeriod,
        subject: this.subject,
        rounds: this.selectedRound,
        every: this.selectedDays,
        first_maintenance: this.firstMaintenance,
        start_of_exec: this.selectedStart,
        facility_id: this.facility_id,

        mode: "create",
      };

      let act = [];
      if (this.is_create) {
        this.showDetails = true;

        act = await ContractServices.saveContractActivity(activity);

        this.selectedService = null;
        this.selectedExpense = null;
        this.selectedMaterials = null;
        this.selectedActivity = null;
        this.selectedUnits = null;
        this.selectedSection = null;
        this.selectedPeriod = null;
        this.selectedBuilding = null;
        this.selectedRound = null;
        this.firstMaintenance = null;
        this.every = null;
        this.selectedDays = null;
        this.subject = null;
        // console.log(act);
        this.$toast.success(`Activity was saved successfully`);
        this.general.expenses = [];
        this.general.services = [];
        this.general.materials = [];
        this.$emit("saveAct", act);
      } else {
        // edit mode

        let selectedContractActivityGuid = this.selectedContractActivity.guid;
        activity.guid = selectedContractActivityGuid;
        activity.mode = "edit";
        act = await ContractServices.saveContractActivity(activity);
        this.showDetails = true;
        this.$toast.success(`Activity was updated successfully`);

        this.$emit("updateAct", act);
      }
    },

    getSelectedExpenses(exp) {
      this.selectedExpense = exp;
    },
    getSelectedServices(serv) {
      this.selectedService = serv;
    },
    getSelectedMaterials(mat) {
      this.selectedMaterials = mat;
    },
  },
};
</script>
<style scoped>
h3 {
  font-size: 1.3em !important;
  font-weight: bold !important;
}
</style>
